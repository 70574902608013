import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import { Box, Container } from "theme-ui";
import Seo from "components/seo";
import Layout from "components/layout";

const PrivacyPage = () => (
    <StickyProvider>
        <Layout header="#4446ed" scrollableLinks={false}>
            <Seo title="Noku - Privacy Policy" />
            <Box as="section">
                <Container>
                    <Box as="div" sx={styles.text}>
                        <h1 sx={{ textAlign: "center" }}>TERMS & CONDITIONS</h1>
                        <small>LAST UPDATE: 29/12/2022</small>
                        <p>
                            Please review the terms of service contained herein
                            carefully. By accessing and/or using any of the
                            Services provided by NOKU SA, You have read,
                            understood, and accepted all of the following Terms,
                            as well as all of the terms which are incorporated
                            herein by reference.
                        </p>
                        <h3>1. TERMS OF SERVICE</h3>
                        The following terms and conditions (the “Terms”)
                        constitute a binding agreement between NOKU SA, a
                        limited company registered under the laws of
                        Switzerland, registered with the Register of Commerce
                        under No. CHE-152.606.044 and with registered address at
                        Via Pelli, 10 – 6900 Lugano (“NOKU” or the “Company”),
                        and the person, persons, or entity (“You” or “Your”)
                        accessing and/or using the noku platform at www.noku.io
                        (the “Site”) as well as the noku application (the “App”)
                        or any other media form, media channel, mobile website
                        or mobile application related, linked, or otherwise
                        connected thereto. By accessing and/or using the Site,
                        You have read, understood, and accepted all of the
                        following Terms and agree to be legally bound by these
                        Terms, in their most recent version; You further
                        represent and warrant that: (i) You are of legal age to
                        enter into a binding agreement; and (ii) if You
                        represent a corporation, governmental organization or
                        other legal entity, You have the right, power and
                        authority to enter into these Terms on behalf of such
                        corporation, governmental organization or other legal
                        entity and to legally bind it to these Terms. Your
                        access and/or use of the Services is contingent upon
                        Your acceptance of these Terms. The Company may amend
                        these Terms at any time. Amendments will be effective 14
                        (fourteen) days after the modified terms and conditions
                        have been posted on the Company’s website at
                        www.noku.io, except for amendments that relate to new
                        features or made for legal reasons, which will become
                        effective immediately. If You do not agree to such
                        modified terms, You should discontinue Your use of the
                        Services.
                        <h3>2. RIGHTS AND OBLIGATIONS</h3>
                        Noku is a distributed application that is currently
                        running on the Ethereum Network and Noku Network (the
                        "Blockchains"), using specially-developed smart
                        contracts (each, a “Smart Contract”) to enable users to
                        own, transfer, battle, and breed genetically unique
                        digital cards. It also enables users to own and transfer
                        other digital assets like plots of land and items. These
                        assets can then be visualized on the Site or the App.
                        Using the App, users can view their assets and use the
                        Smart Contracts to acquire, trade, battle, and breed
                        creatures with other App users (all together the
                        “Service”).
                        <br />
                        <br />
                        The Service is not intended for distribution to any
                        person or entity in any jurisdiction or country where
                        such distribution or use would be contrary to law or
                        regulation, or which would subject us to any
                        registration requirement within such jurisdiction or
                        country. Accordingly, any persons who access the Site
                        and/or the App while breaking their local laws are doing
                        it on their own initiative and will have to deal with
                        the consequences when applicable. The Serviceis intended
                        for users who are at least 18 years old. People under
                        the age of 18 are not permitted to use or register for
                        the Site, the App, and the Smart Contracts.
                        <br />
                        <h3>3. INTELLECTUAL PROPERTY RIGHTS</h3>
                        Noku is a decentralized blockchain-based online gaming
                        platform. All brands, games, tokens, NFTs, and generally
                        any assets on the platform are owned by their
                        publishers. <br />
                        The intellectual property of the games or assets created
                        and implemented on the platform, which are then traded
                        or sold between users, belongs to Noku, third parties
                        publishers or third parties creators.
                        <br />
                        Unless otherwise stated, the Site, App and Smart
                        Contracts are owned by us, or the third parties
                        publishers, and all source code, databases,
                        functionality, software, website design, audio, video ,
                        text, photographs and graphics on the Site and Apps
                        (collectively, the "Content") and the trademarks,
                        service marks and logos contained therein (the
                        "Trademarks") are owned by us, or their respective
                        publishers, and are protected by copyright and trademark
                        laws and various other intellectual property rights and
                        unfair competition laws of Switzerland, foreign
                        jurisdiction and international conventions. Except as
                        expressly provided in these Terms, no part of the Site,
                        App and Smart Contract and no Content or Marks may be
                        copied, reproduced, aggregated, republished, uploaded,
                        posted, publicly displayed, encoded, translated,
                        transmitted, distributed, sold, licensed or otherwise
                        exploited for any commercial purpose, without our
                        express written permission.
                        <br />
                        Provided you are eligible to use the Site, App and Smart
                        Contracts, you are granted a limited license to access
                        and use the Site or to download or print a copy of any
                        Content to which you have properly obtained permission.
                        access for your personal, non-commercial use only. We
                        reserve all rights not expressly granted to you in and
                        to the Site, App, Content and Trademarks.
                        <br />
                        <h3>4. USER REPRESENTATIONS</h3>
                        By using the Site, the App and the Smart Contracts, you
                        represent and warrant that: (1) all registration
                        information you submit will be true, accurate, current,
                        and complete; (2) you will maintain the accuracy of such
                        information and promptly update such registration
                        information as necessary; (3) you have the legal
                        capacity and you agree to comply with these Terms; (4)
                        you are not a minor in the jurisdiction in which you
                        reside; (5) you will not access the Site, the App and
                        the Smart Contracts through automated and non-human
                        means, whether through a bot, script or otherwise. (6)
                        you will not use the Site, the App, and the Smart
                        Contracts for any illegal and unauthorized purpose; and
                        (7) your use of the Site, the App, and the Smart
                        Contracts will not violate any applicable law or
                        regulation. If you provide any information that is
                        untrue, inaccurate, not current, or incomplete, we have
                        the right to suspend or terminate your account and
                        refuse any and all current or future use of the Site,
                        the App, and the Smart Contracts (or any portion
                        thereof). (8) you can only qualify for one leaderboard
                        reward each season
                        <br />
                        Noku reserves the right to choose which markets and
                        jurisdictions to conduct its business and may restrict
                        or refuse, at its sole discretion, the provision of Noku
                        services in certain countries or regions.
                        <br />
                        <h3>5. USER REGISTRATION</h3>
                        You may be required to register with the Site or the
                        App. You agree to keep your password confidential and
                        will be responsible for all use of your account and
                        password. We reserve the right to remove, reclaim or
                        change a username you select if we determine, in our
                        sole discretion, that such username is inappropriate,
                        obscene, or otherwise objectionable.
                        <br />
                        <h3>6. FEE AND PAYMENT</h3>
                        Any purchases from the site or marketplace will be done
                        through smart contracts on a blockchain using a
                        Nokuchain Wallet. Any financial transactions that you
                        engage in will be conducted solely through the
                        Blockchain via a wallet such Nokuchain Wallet. We will
                        have no insight into or control over these payments or
                        transactions, nor do we have the ability to reverse any
                        transactions. With that in mind, we will have no
                        liability to you or to any third party for any claims or
                        damages that may arise as a result of any transactions
                        that you engage in via the Site and/or the App or using
                        the Smart Contracts, or any other transactions that you
                        conduct via the Nokuchain.
                        <br />
                        <h3>7. IN-GAME RULES</h3>
                        A violation of any of the following points may result in
                        a ban. Sanctions will be evaluated individually and they
                        can result in a minimum of a warning ban up to a
                        permanent one.
                        <br />
                        Bug Abuse: players that find bugs in our platform and
                        use it several times without reporting the problem to
                        the team may be banned straight away.
                        <br />
                        MultiAccount: playing with several accounts in order to
                        move prizes from the multi to the main account can
                        result in a ban.
                        <br />
                        Market Manipulation: Sell Rare players for really low
                        price or common players for really high price in order
                        to move gained credits or gained cards through accounts
                        may result in a ban.
                        <br />
                        <h3>8. SUBMISSION</h3>
                        You acknowledge and agree that any questions, comments,
                        suggestions, ideas, feedback, or other information
                        regarding the Site, the App and the Smart Contracts
                        (“Submissions") provided by you to us are
                        non-confidential and should become our sole property. We
                        should own exclusive rights, including all intellectual
                        property rights, and should be entitled to the
                        unrestricted use and dissemination of these Submissions
                        to any lawful purpose, commercial, or otherwise, without
                        acknowledgment or compensation for you. You hereby waive
                        any moral rights to any such Submissions, and you hereby
                        warrant that any such Submissions are original with you
                        or that you have the right to submit such Submissions.
                        You agree there should be no recourse against us for any
                        alleged or actual infringement or misappropriation of
                        any proprietary right in your Submissions.
                        <br />
                        <h3>9. ADVERTISER</h3>
                        We allow advertisers to display their advertisements and
                        other information in certain areas of the Site and the
                        App such as sidebar advertisements or banner
                        advertisements. If you are an advertiser, you should
                        take full responsibility for any advertisements you
                        place on the Site and/or the App, and any services
                        provided on the Site and/or the App, or products sold
                        through those advertisements. Further, as an advertiser,
                        you warrant and represent that you possess all rights
                        and authority to place advertisements on the Site and/or
                        the App, including, but not limited to, intellectual
                        property rights, publicity rights, and contractual
                        rights. We simply provide the space to place such
                        advertisements, and we have no other relationship with
                        advertisers.
                        <br />
                        <h3>10. TERMINATION</h3>
                        These Terms remain in full force and effect while you
                        use the Site, the App and the Smart Contracts. WITHOUT
                        LIMITING ANY OTHER PROVISION OF THESE TERMS, WE RESERVE
                        THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE
                        OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE, THE
                        APP AND THE SMART CONTRACT (INCLUDING BLOCKING CERTAIN
                        IP ADDRESSES) TO ANY PERSON FOR ANY REASON OR FOR NO
                        REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                        REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                        TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE
                        MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE, THE
                        APP AND THE SMART CONTRACT OR DELETE YOUR ACCOUNT
                        WITHOUT WARNING, IN OUR SOLE DISCRETION.
                        <br />
                        If we terminate or suspend your account for any reason,
                        you are prohibited from registering and creating a new
                        account under your name, a fake or a borrowed name, or
                        the name of any third party, even if you may be acting
                        on behalf of the third party. In addition to terminating
                        and suspending your account, we reserve the right to
                        take appropriate legal action, including without
                        limitation pursuing civil, criminal, and injunctive
                        redress.
                        <br />
                        <h3>11. GOVERNING LAW</h3>
                        This Terms and the Servicesare governed by and
                        constructed in accordance with the laws of Switzerland
                        applicable to agreements made and to be entirely
                        performed in Switzerland, without, regard to its
                        conflicts of law principles.
                        <br />
                        <h3>12. DISPUTE RESOLUTION</h3>
                        To expedite resolution and control the cost of any
                        dispute, controversy, or claim related to these Terms of
                        Use (each a “Dispute" and collectively, the “Disputes")
                        brought by either you or us (individually, a “Party" and
                        collectively, the “Parties"), the Parties agree to first
                        attempt to negotiate any Dispute (except those Disputes
                        expressly provided below) informally for at least thirty
                        (30) days before initiating the arbitration. Such
                        informal negotiations commence upon written notice from
                        one Party to the other Party.
                        <br />
                        <h3>13. DISCLAIMERS</h3>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO
                        AND USE OF THE SITE, THE APP AND THE SMART CONTRACTS ARE
                        AT YOUR SOLE RISK, AND THAT THE SITE, THE APP AND THE
                        SMART CONTRACTS ARE PROVIDED "AS IS" AND "AS AVAILABLE"
                        WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                        IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO
                        APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES, AND
                        LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM
                        ALL IMPLIED WARRANTIES REGARDING THE SITE, THE APP AND
                        THE SMART CONTRACTS AND ANY PART OF IT (INCLUDING,
                        WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY
                        EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY.
                        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE,
                        OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT
                        REPRESENT OR WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR
                        USE OF THE SITE, THE APP AND THE SMART CONTRACTS WILL
                        MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF
                        THE SITE, THE APP AND THE SMART CONTRACTS WILL BE
                        UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, (III)
                        USAGE DATA PROVIDED THROUGH THE SITE, THE APP AND THE
                        SMART CONTRACTS WILL BE ACCURATE, (III) THE SITE, THE
                        APP AND THE SMART CONTRACTS OR ANY CONTENT, SERVICES, OR
                        FEATURES MADE AVAILABLE ON OR THROUGH THE SITE, THE APP
                        AND THE SMART CONTRACTS ARE FREE OF VIRUSES OR OTHER
                        HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT YOU
                        DISCLOSE WHEN YOU USE THE SITE, THE APP AND THE SMART
                        CONTRACTS WILL BE SECURE. SOME JURISDICTIONS DO NOT
                        ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS
                        WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS
                        MAY NOT APPLY TO YOU.
                        <br />
                        YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                        INFORMATION AND DEALING ONLINE OVER THE INTERNET AND
                        AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR
                        ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS
                        NEGLIGENCE. WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU
                        FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF
                        THE ETHEREUM NETWORK, BSC NETWORK, FANTOM NETWORK AND
                        THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT
                        LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM:
                        (A) USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR
                        INCORRECTLY CONSTRUED SMART CONTRACTS OR OTHER
                        TRANSACTIONS; (B) SERVER FAILURE OR DATA LOSS; (C)
                        CORRUPTED WALLET FILES; (D) UNAUTHORIZED ACCESS OR
                        ACTIVITIES BY THIRD PARTIES, INCLUDING BUT NOT LIMITED
                        TO THE USE OF VIRUSES, PHISHING, BRUTE FORCING OR OTHER
                        MEANS OF ATTACK AGAINST THE APP, ETHEREUM NETWORK, OR
                        THE WALLET.
                        <br />
                        <h3>14. LIMITATION OF LIABILITY</h3>
                        In no event will the Company, its officers,
                        shareholders, employees, agents, directors,
                        subsidiaries, affiliates, successors, assigns,
                        suppliers, or licensors be liable for any loss or
                        damages, including without limitation, direct, indirect,
                        special, consequential, or punitive loss or damages,
                        arising from or related to Your use of the Services,
                        whether in an action of contract, tort or otherwise, and
                        regardless of whether such damages were foreseeable and
                        whether or not The Company was advised of the
                        possibility of such damages.
                        <br />
                        Without limiting the generality of the foregoing, the
                        Company takes no responsibility for and will not be
                        liable for any financial or other loss or damages
                        arising from or related to the use of the Services,
                        including, without limitation, to any of the following:
                        (i) loss of or inability to access or transact data,
                        cryptocurrencies or digital assets; (ii) technical
                        failure, malfunction or shutdown; (iii) server failure,
                        hacks or unavailability; (iv) data loss; (v) stolen,
                        lost, or unauthorized use of Your means of
                        authorization; (vi) errors calculating network fees;
                        (vii) corrupted data on The Company’s servers; (viii)
                        incorrectly constructed transactions or mistyped wallet
                        addresses; (ix) failure to update or provide correct
                        information; (x) “phishing” or other websites
                        masquerading as The Company; (xi) delays, interruption
                        or loss of services; (xii) loss of business or goodwill.
                        <br />
                        The Services support or are integrated with third party
                        services. The Company takes no responsibility for any
                        third party services and will not be liable for any loss
                        or damages caused by such third party services.
                        <br />
                        The Services are provided on an “as is” and “as
                        available” basis without any representation or warranty
                        of any kind, whether express or implied, to the maximum
                        extent permitted by applicable laws. The Company
                        disclaims any implied warranties of title,
                        merchantability, fitness for a particular purpose and/or
                        non-infringement.
                        <br />
                        <h3>15. ASSUMPTION OF RISK</h3>
                        You accept and acknowledge each of the following:
                        <br />
                        A. The prices of blockchain assets are extremely
                        volatile. Fluctuations in the price of other digital
                        assets could materially and adversely affect the value
                        of your Nokus, which may also be subject to significant
                        price volatility. We cannot guarantee that any
                        purchasers o Noku will not lose money.
                        <br />
                        B. You are solely responsible for determining what, if
                        any, taxes apply to your Noku-related transactions.
                        Nokuy is not responsible for determining the taxes that
                        apply to your transactions on the App, the Site, or the
                        Smart Contracts.
                        <br />
                        C. The App does not store, send, or receive Noku. This
                        is because Noku exist only by virtue of the ownership
                        record maintained on the App’s supporting blockchain.{" "}
                        <br />
                        D. There are risks associated with using an
                        Internet-based currency, including, but not limited to,
                        the risk of hardware, software and Internet connections,
                        the risk of malicious software introduction, and the
                        risk that third parties may obtain unauthorized access
                        to information stored within your wallet. <br />
                        E. The regulatory regime governing blockchain
                        technologies, cryptocurrencies, and tokens is uncertain,
                        and new regulations or policies may materially adversely
                        affect the development of the Noku ecosystem, and
                        therefore the potential utility or value of Noku.
                        <br />
                        <h3>16. INDEMNIFICATION</h3>
                        You agree to defend, indemnify, and hold us harmless,
                        including our subsidiaries, affiliates, and all of our
                        respective officers, agents, partners, and employees,
                        from and against any loss, damage, liability, claim, or
                        demand, including reasonable attorneys' fees and
                        expenses, made by third party due to or arising out of:
                        (1) use of the Site, (2) breach of these Terms of Use,
                        (3) any breach of your representations and warranties
                        set forth in these Terms of Use, (4) your violation of
                        the rights of a third party, including but not limited
                        to intellectual property rights, or (5) any overt
                        harmful act toward any other use of the Site, the App
                        and the Smart Contracts with whom you connected via the
                        Site, the App and the Smart Contracts. Notwithstanding
                        the foregoing, we reserve the right, at your expense, to
                        assume the exclusive defense and control of any matter
                        for which you are required to indemnify us, and you
                        agree to cooperate, at your expense, with our defense of
                        such claims. We will use reasonable efforts to notify
                        you of any such claim, action or proceeding which is
                        subject to this indemnification upon becoming aware of
                        it.
                        <br />
                        <h3>17. USER DATA</h3>
                        We will maintain certain data that you transmit to the
                        Site, the App and the Smart Contracts for the purpose of
                        managing the performance of the Site, the App and the
                        Smart Contracts, as well as data relating to your use of
                        the Site, the App and the Smart Contracts. Although we
                        perform regular routine backups of data, you are solely
                        responsible for all data that you transmit or that
                        release to any activity you have undertaken using the
                        Site, the App and the Smart Contracts. You agree that we
                        shall have no liability to you for any loss or
                        corruption of any such data, and you hereby waive any
                        right of action against us arising from any such loss or
                        corruption of such data.
                        <br />
                        <h3>18. MISCELLANEOUS</h3>
                        These Terms and any policies or operating rules posted
                        by us on the Site or the App or in respect to the Site,
                        the App and the Smart Contracts constitute the entire
                        agreement and understanding between you and us. Our
                        failure to exercise or enforce any right or provision of
                        these Terms shall not operate as a waiver of such right
                        or provision. These Terms operate to the fullest extent
                        permissible by law. We may assign any or all of our
                        rights and obligations to others at any time. We shall
                        not be responsible or liable for any loss, damage,
                        delay, or failure to act caused by any cause beyond our
                        reasonable control. If any provision or part of a
                        provision of these Terms is determined to be unlawful,
                        void, and unenforceable, that provision or part of the
                        provision is deemed severable these Terms of Use and
                        does not affect the validity and enforceability of any
                        remaining provisions. There is no joint venture,
                        partnership, employment or agency relationship created
                        between you and us as a result of these Terms or use of
                        the Site. You agree that these Terms will not be
                        construed against us by virtue of having drafted them.
                        You hereby waive any and all defenses you may have based
                        on the electronic form of these Terms and the lack of
                        signing by the parties hereto to execute these Terms{" "}
                        <br />
                        <h2>PRIVACY POLICY</h2>
                        <h3>General Informations</h3>
                        <p>
                            This "Privacy Policy" is applicable for your Use in
                            every manner of the Website and the Tokens. This
                            Privacy Policy will apply to all Uses of any of the
                            Tokens and access of the Website from any device and
                            any operating system or platform. All external links
                            which might be accessible from the Website may or
                            will have their own terms and conditions, usage, and
                            privacy policies, and We and our Affiliated Parties
                            and partners do not control them and bear no
                            responsibility for any consequence arising from
                            their usage. The Privacy Policy will be binding on
                            all persons (natural or legal) who Use the Tokens or
                            access the Website from any jurisdiction where they
                            are legally available for access or use or across
                            the world. This Privacy Policy will be the document
                            on the basis of which your consent has been taken
                            for use and transfer of private information
                            depending on the country where you use the Tokens or
                            access the Website. Your continued usage of the
                            Website and/or usage of Tokens and/or participation
                            in the Token Sale means that you have read and
                            understood this Privacy Policy and provide valid
                            consent to the same as per the laws applicable to
                            you. In case you do not agree with this Privacy
                            Policy, you should immediately stop the use of the
                            Tokens and/or the Website. In case you are resident
                            within the European Union, your consent to this
                            Privacy Policy shall also be considered as
                            “unambiguous consent” in terms of Article 7 of
                            Directive 95/46/EC of the European Parliament and of
                            the Council of 24 October 1995 whereby you have
                            permitted the collection, transfer, and processing
                            of your data anywhere across the world.
                        </p>
                        <h3>Collecting of Personal Information</h3>
                        <p>
                            We collect your data when you use our Website, open
                            an account for the purchase or Tokens and/or
                            register on our Website and/or purchase or attempt
                            to purchase a Token.
                            <br />
                            <br />
                            The purpose of personal data collection, storage,
                            and processing within by the Website Owner and NOKU
                            is for the purpose of providing you with the
                            services of the NOKU platform including Use of the
                            Token and/or purchase of the Tokens and/or use of
                            the Website. <br />
                            <br />
                            We collect personally identifiable information (
                            when needed) including your name, email, address,
                            telephone and mobile numbers, device information, IP
                            address, geographical information, web pages
                            visited, age, gender, weight, or any other
                            information that you provide Us through forms or
                            data collection pages within the App including the
                            form filled while registering the Account.
                            Information is also collected automatically through
                            your continued regular usage of Tokens and the
                            Website. The information collected as mentioned in
                            this clause is retained in our server and/or the
                            backup server and We cannot entertain requests for
                            deletion of such information without restricting
                            your access to services and blocking you from the
                            Website and the NOKU ecosystem. Request for deletion
                            may be made through the contact details and/or form
                            provided on the Website. <br />
                            <br /> We collect additional information to comply
                            with Regulatory guidelines and to comply with
                            anti-money laundering (AML) or “know-your-customer”
                            (KYC) policies).
                            <br />
                            <br /> We may require You to provide additional
                            information with documentary proof including, and
                            namely:
                            <br /> • Date of birth;
                            <br /> • Passport or driver license details;
                            <br /> • Utility bills;
                            <br />• Photographs. <br />
                            <br />
                            The information and documents may be sought in
                            printed form or through email or online submission
                            or any other mode as deemed fit by the Website
                            Owner. We may refuse (in some restricted case) to
                            allow you to Use the Tokens and/or the Website
                            and/or any other services without verification of
                            your personal information and documentation as we
                            deem fit. <br />
                            <br />
                            Our partners and affiliates may also collect
                            personally identifiable information including name,
                            email, address, telephone and mobile numbers, age,
                            gender, weight, or any other information that you
                            provide them through forms or data collection pages
                            within the Website and the NOKU ecosystem if you
                            decide to communicate with them or use their
                            services. <br />
                            <br />
                            We do not control the storage and usage of such
                            personal information by our partners and affiliates
                            and We do not control any of their services. We also
                            accept no responsibility for any of their claims and
                            services and request you to exercise caution before
                            using their services and contact them directly for
                            any issues which arise from your use of their
                            services. We may use the information provided by you
                            to provide you with additional information and
                            services, improve our platform, and continue to
                            provide you with services on the NOKU ecosystem in
                            any manner as deemed fit by the Website Owner, but
                            we'll never sell your data. <br />
                            <br />
                            All information including documents collected from
                            You at any stage through any manner may be shared
                            with Affiliated Parties and or any other parties
                            including banks and financial authorities as
                            required by us to enable services on the NOKU
                            ecosystem. All information including documents
                            collected from You at any stage through any manner
                            may be shared (if strictly needed) with law
                            enforcement agencies and regulatory bodies across
                            the world. <br />
                            <br />
                            Information shared with law enforcement agencies,
                            regulatory bodies, Affiliated Parties and or any
                            other parties including banks and financial
                            authorities may be stored by them and transmitted as
                            they deem fit. Such information will not be deleted
                            even when you seek to delete the information stored
                            with Us. <br />
                            <br />
                            We may store all information including documents
                            collected from You even after receipt of a request
                            for deletion to comply with regulations and
                            guidelines from governments and law enforcement
                            agencies. <br />
                            <br />
                            You have a right to access to Your Personal Data and
                            to require the correction, updating or deletion of
                            incorrect or/and inaccurate data by contacting Us
                            via the contact information provided on the Website.
                            Nevertheless, this request must comply with other
                            documents included within the Agreements and our
                            legal obligations.
                        </p>
                        <h3>Storage of personal data</h3>{" "}
                        <p>
                            NOKU ensured that appropriate security standards are
                            in place regarding the safeguarding, confidentiality
                            and security of Data. In regard to data subjects
                            resident in the EU, NOKU, where required, registers
                            with appropriate national data
                            protection/information protection authorities. NOKU
                            also ensures that data is held in a manner that
                            meets the requirements of any national law of any
                            member state of the European Union giving effect to
                            Directive 95/46/EC. The Directive provides
                            obligations on the protection of individuals with
                            regard to the processing of personal data and on the
                            free movement of such data.{" "}
                        </p>
                        <h3>Acceptance</h3>
                        <p>
                            By using this site, you signify your agreement to
                            this Policy. NOKU reserves the right to change this
                            Policy at any time. Any changes will be posted so
                            that you are always aware of what information we
                            collect, how we use it and under what circumstances
                            we disclose it.
                        </p>
                        <h3>Questions</h3>
                        <p>
                            Any questions about this policy, the collection,
                            use, and disclosure of Personal Data by NOKU or
                            access to your Personal Data which is required by
                            law to be disclosed should be directed to
                            info@noku.io
                        </p>
                    </Box>
                </Container>
            </Box>
        </Layout>
    </StickyProvider>
);
const styles = {
    text: {
        marginTop: "130px",
        marginBottom: "100px",
        color: "#fff",
    },
};

export default PrivacyPage;
